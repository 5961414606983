import React from "react";
// icons
import { BsArrowUpRight } from "react-icons/bs";
// motion
import { motion } from "framer-motion";
// variant
import { fadeIn } from "../variants";
import { Link } from "react-scroll";
// services data
const services = [
  {
    name: "UI/UX Design",
    description:
      "Lorem, ipsum dolor sit amet, consectetur adipiscing elit. Maiores, quia quo expedita accusamus illum ducimus tempore reepellat distinctio harum aperiam.",
    link: "Learn more",
  },
  {
    name: "Development",
    description:
      "Lorem, ipsum dolor sit amet, consectetur adipiscing elit. Maiores, quia quo expedita accusamus illum ducimus tempore reepellat distinctio harum aperiam.",
    link: "Learn more",
  },
  {
    name: "Digital Marketing",
    description:
      "Lorem, ipsum dolor sit amet, consectetur adipiscing elit. Maiores, quia quo expedita accusamus illum ducimus tempore reepellat distinctio harum aperiam.",
    link: "Learn more",
  },
  {
    name: "Product Branding",
    description:
      "Lorem, ipsum dolor sit amet, consectetur adipiscing elit. Maiores, quia quo expedita accusamus illum ducimus tempore reepellat distinctio harum aperiam.",
    link: "Learn more",
  },
];

const Services = () => {
  return (
    <section className="section" id="services">
      <div className="container mx-auto">
        <div className="flex">
          {/** text */}
          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0"
          >
            <motion.h2
              variants={fadeIn("up", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="h2 text-accent mb-6"
            >
              What I Do.
            </motion.h2>
            <motion.h3
              variants={fadeIn("up", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="h3 max-w-[455px] mb-16"
            >
              I`am Freelance Front-and Developer with over 5 years of experience
            </motion.h3>
            <button className="btn btn-sm">See my work</button>
          </motion.div>
          {/** services */}
          <motion.div
            variants={fadeIn("right", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="flex-1 lg:flex-row"
          >
            {/** services list */}
            <motion.div
              variants={fadeIn("up", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              {services?.map((services, index) => {
                // destructure service
                const { name, description, link } = services;
                return (
                  <div
                    key={index}
                    className="border-b border-white/20 h-[146px] mb-[38px] flex"
                  >
                    <div className="max-w-[476px]">
                      <h4 className="text-[20px] translate-wider font-primary font-semibold mb-6">
                        {name}
                      </h4>
                      <p className="font-secondary leading-tight">
                        {description}
                      </p>
                    </div>
                    <div className="flex flex-col flex-1 items-end">
                      <Link
                        to="#"
                        className="btn w-9 h-9 mb-[42px] flex justify-center items-center"
                      >
                        <BsArrowUpRight />
                      </Link>
                      <Link to="#" className="text-gradient text-sm">
                        {link}
                      </Link>
                    </div>
                  </div>
                );
              })}
            </motion.div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
